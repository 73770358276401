import { FormControlLabel, makeStyles, Radio, TextField } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, TextButton } from "../../../components/button"
import { BackButton } from "../../../components/buttons/back_btn"
import { BaseContainer } from "../../../components/container"
import { RecordSteps } from "../../../components/context/recordReducer"
import { Product, RecordContext, useRecordContext } from "../../../components/context/recordState"
import ErrorDialog from "../../../components/dialogs/error_dialog"
import { Divider } from "../../../components/divider"
import { HeaderTitle } from "../../../components/page_sections"
import RadioCardGroup from "../../../components/radio_card_group"
import { StickyFooterGroup } from "../../../components/sticky_footer_group"
import Typography from "../../../components/typography"
import { axiosAuthedInstance, AxiosError } from "../../../utils/axiosApi"

const NorthProducts: Product[] = [
    {
        "id": "HK-42956",
        "label": "Fluvax"
    },
    {
        "id": "HK-43003",
        "label": "Fluarix"
    },
    {
        "id": "HK-51268",
        "label": "Influvac"
    },
    {
        "id": "HK-63011",
        "label": "Fluquadri"
    },
    {
        "id": "HK-63226",
        "label": "Fluarix Tetra"
    },
    {
        "id": "HK-64982",
        "label": "Influsplit Tetra"
    },
    {
        "id": "HK-65577",
        "label": "Vaxigrip Tetra",
        "priority": true
    },
    {
        "id": "HK-65690",
        "label": "Flumist Quadrivalent",
        "priority": true
    },
    {
        "id": "HK-67049",
        "label": "Flublok",
        "priority": true
    },
    {
        "id": "HK-66197",
        "label": "Influvac Tetra",
        "priority": true
    },
    {
        "id": "IMMU-PRODUCT-VAXIGRIP-NORTH",
        "label": "Vaxigrip"
    },
    {
        "id": "IMMU-PRODUCT-INTANZA",
        "label": "Intanza"
    },
    {
        "id": "IMMU-PRODUCT-FLUZONE-QUADRI",
        "label": "Fluzone Quadrivalent"
    },
    {
        "id": "IMMU-PRODUCT-INFLEXAL-V",
        "label": "Inflexal V"
    },
    {
        "id": "IMMU-PRODUCT-FLULAVAL-QUAD",
        "label": "Flulaval Quadrivalent"
    },
    {
        "id": "IMMU-PRODUCT-AGRIFLU",
        "label": "Agriflu"
    },
    {
        "id": "IMMU-PRODUCT-FLUAD-NORTH",
        "label": "Fluad"
    },
    {
        "id": "IMMU-PRODUCT-FLUMIST",
        "label": "Flumist"
    }
]

const SouthProducts: Product[] = [
    {
        "id": "HK-43047",
        "label": "Fluarix"
    },
    {
        "id": "HK-54227",
        "label": "Fluvax"
    },
    {
        "id": "HK-62235",
        "label": "Fluarix Tetra",
        "priority": true
    },
    {
        "id": "HK-65607",
        "label": "Fluquadri"
    },
    {
        "id": "IMMU-PRODUCT-VAXIGRIP-SOUTH",
        "label": "Vaxigrip"
    },
    {
        "id": "IMMU-PRODUCT-FLUZONE-QUADRI-SOUTH",
        "label": "Fluzone Quadrivalent"
    },
    {
        "id": "IMMU-PRODUCT-INFLUVAC-TETRA",
        "label": "Influvac Tetra"
    },
    {
        "id": "IMMU-PRODUCT-VAXIGRIP-TETRA-SOUTH",
        "label": "Vaxigrip Tetra",
        "priority": true
    },
    {
        "id": "IMMU-PRODUCT-INTANZA-SOUTH",
        "label": "Intanza"
    },
    {
        "id": "IMMU-PRODUCT-AFLURIA",
        "label": "Afluria",
        "priority": true
    },
    {
        "id": "IMMU-PRODUCT-FLUAD-SOUTH",
        "label": "Fluad",
        "priority": true
    },
    {
        "id": "IMMU-PRODUCT-FLUVIRIN",
        "label": "Fluvirin"
    },
    {
        "id": "IMMU-PRODUCT-INFLUVAC-SOUTH",
        "label": "Influvac"
    },
    {
        "id": "IMMU-PRODUCT-FLUBLOK-SOUTH",
        "label": "Flublok"
    }
]

export const GenericProducts: {[key in 'NORTH' | 'SOUTH']: Product} = {
    NORTH: {
        id: 'IMMU-GENERIC-FLU-NORTH',
        isGeneric: true,
    },
    SOUTH: {
        id: 'IMMU-GENERIC-FLU-SOUTH',
        isGeneric: true,
    }
}

type CheckDuplicateResponse = {
    is_duplicate: boolean
}

const useStyles = makeStyles((theme) => ({
    textButton: {
        marginTop: theme.spacing(2),
    }
}))

export const SetProduct = () => {
    const classes = useStyles()
    const history = useHistory()
    const [isFormValid, setIsFormValid] = useState(false)
    const {state, dispatch} = useRecordContext(RecordContext)
    const [productList, setProductList] = useState<Product[]>()
    const [selected, setSelected] = useState('')
    const [otherProduct, setOtherProduct] = useState('')
    const [showTextField, setShowTextField] = useState(false)
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!state.hemisphere) return

        let productList
        switch (state.hemisphere) {
            case 'NORTH':
                productList = NorthProducts
                break
            case 'SOUTH':
                productList = SouthProducts
                break
        }
        // sort product list in alphabetically order
        productList.sort((a, b) => (a.label as string).localeCompare(b.label as string))
        setProductList(productList)

        if (state.otherProduct) {
            setOtherProduct(state.otherProduct)
            setSelected('other')
        } else if (state.product?.id) {
            // If we go back and change our hemisphere, we may not have a valid product any more
            if (!state.product.isGeneric && productList.some(p => p.id === state.product?.id)) {
                setSelected(state.product.id)
            }
        }

    }, [state.hemisphere, state.product, state.otherProduct])

    useEffect(() => {
        if (!selected) {
            setIsFormValid(false)
            return
        }

        if (selected == 'other') {
            setShowTextField(true)
            if (otherProduct) {
                setIsFormValid(true)
            } else {
                setIsFormValid(false)
            }
        } else {
            setShowTextField(false)
            setIsFormValid(true)
        }
    }, [selected, otherProduct])

    function handleSubmit() {
        let product: Product | undefined

        if (selected === 'other') {
            if (!state.hemisphere) {
                throw "Hemisphere not defined"
            }

            product = GenericProducts[state.hemisphere]
            dispatch({
                type: RecordSteps.SET_PRODUCT,
                payload: {
                    product: product,
                    otherProduct: otherProduct
                }
            })
        } else {
            product = productList?.find(p => p.id === selected)

            if (!product) {
                throw `Product not defined for option: '${selected}'`
            }

            dispatch({
                type: RecordSteps.SET_PRODUCT,
                payload: {
                    product: product,
                }
            })
        }

        redirect(product)
    }

    function iDontknowSubmit() {
        if (!state.hemisphere) {
            throw "Hemisphere not defined"
        }

        const product = GenericProducts[state.hemisphere]
        dispatch({
            type: RecordSteps.SET_PRODUCT,
            payload: {
                product: product
            }
        })

        redirect(product)
    }

    /**
     * Performs a duplication check before redirecting to either the summary or the error page
     */
    function redirect(product: Product) {
        setLoading(true)

        const payload = {
            product_id: product.id,
            date: state.date as string
        }
        // Check duplication before summary page
        axiosAuthedInstance.post<CheckDuplicateResponse>(
            `user/${state.user.uuid}/check-record/`, payload
        ).then((response) => {
            if (response.data.is_duplicate == true) {
                history.push(`/add-record/${state.user.uuid}/record-duplicate`)
            } else if (response.data.is_duplicate == false) {
                history.push(`/add-record/${state.user.uuid}/summary`)
            }
        }).catch((error: AxiosError) => {
            console.error(error)
            if (error.response?.status == 403) {
                setError('You do not have permission to add record for this user.')
            } else {
                setError('Server error. Please try again later.')
            }
            setShowError(true)
        }).finally(() => {
            setLoading(false)
        })
    }

    return(
        <BaseContainer noHeader showCta extraComponent={
            <StickyFooterGroup>
                <Button primary fullWidth onClick={handleSubmit} loading={loading} disabled={!isFormValid}>
                    Next
                </Button>
            </StickyFooterGroup>
        }>
            <BackButton/>
            <HeaderTitle>
                Do you know the name of the vaccine brand administered?
            </HeaderTitle>
            <RadioCardGroup
                name="product"
                onChange={(e: React.ChangeEvent<{
                    value: string;
                }>) => setSelected(e.target.value)}
                value={selected}
            >
                {productList?.map((product) => {
                    if(product.priority) {
                        return(
                            <FormControlLabel
                                key={product.id}
                                value={product.id}
                                control={<Radio color="primary" size="small"/>}
                                label={
                                    <Typography variant="bodyTextMedium">
                                        {product.label}
                                    </Typography>
                                }
                                labelPlacement="end"
                            />
                        )
                    }
                })}
                <Divider/>
                {productList?.map((product) => {
                    if(!product.priority) {
                        return(
                            <FormControlLabel
                                key={product.id}
                                value={product.id}
                                control={<Radio color="primary" size="small"/>}
                                label={
                                    <Typography variant="bodyTextMedium">
                                        {product.label}
                                    </Typography>
                                }
                                labelPlacement="end"
                            />
                        )
                    }
                })}
                <FormControlLabel
                    key="other"
                    value="other"
                    control={<Radio color="primary" size="small"/>}
                    label={
                        <Typography variant="bodyTextMedium">
                            Other
                        </Typography>
                    }
                    labelPlacement="end"
                />
                {showTextField &&
                    <>
                        <TextField
                            id="vaccinationProduct"
                            name="vaccinationProduct"
                            placeholder="Vaccination Product"
                            label = "Vaccination Product"
                            fullWidth
                            margin="normal"
                            value={otherProduct}
                            onChange={event => setOtherProduct(event.target.value)}
                            required
                        />
                        <Typography variant="bodyTextSmall">
                            Note this won’t be displayed as an official product but the vaccine name you enter will be shown on your record.
                        </Typography>
                    </>
                }
            </RadioCardGroup>
            <TextButton
                className={classes.textButton}
                onClick={() => iDontknowSubmit()}
            >
                I don't know
            </TextButton>
            <ErrorDialog
                error={error}
                open={showError}
                setOpen={setShowError}
            />
        </BaseContainer>
    )
}
