import Typography from "../typography";
import { TuberculosisContent } from "./tuberculosis";
import { InfoPanel } from "../info_panel";
import { MeaslesContent } from "./measles";
import { InfluenzaContent } from "./influenza";
import { CovidContent } from "./covid";
import { HpvContent } from "./hpv";
import { ShinglesContent } from "./shingles";
import { OutputBlockData } from "@editorjs/editorjs"
import { JsonContent, StaticHTML } from "../static_content";
import { VaccinationStatus } from "../vaccination_progress";

/** Mapping of JSON content file name (without extension) to its disease ID */
const diseaseIdMap = {
    'cholera': '63650001',
    'dengue': '38362002',
    'diphtheria': '3419005',
    'hep_a': '40468003',
    'hep_b': '66071002',
    'hib': '406583002',
    'je': '52947006',
    'mumps': '36989005',
    'pertussis': '27836007',
    'pneumococcal': '16814004',
    'polio': '398102009',
    'rabies': '14168008',
    'meningococcal': '23511006',
    'varicella': '38907003',
    'tetanus': '76902006',
    'rubella': '36653000',
    'rotavirus': '18624000',
    'typhoid': '4834000',
    'mpox': '359814004',
    'shingles': '4740000',
    'yellow_fever': '16541001',
    'tbe': '712986001',
}

/** Mapping from disease ID to its actual content */
const idContentsMap: {[key: string]: OutputBlockData[]} = {}

const diseaseContents = require.context('../../content', false, /\.json$/)
diseaseContents.keys().forEach(k => {
    // strip `./` from start and `.json` from end of filename to get the disease name
    const diseaseName = k.slice(2, -5) as keyof typeof diseaseIdMap

    const diseaseId = diseaseIdMap[diseaseName]
    if (diseaseId) {
        idContentsMap[diseaseId] = diseaseContents(k) as OutputBlockData[]
    }
})

type DiseaseContentProps = {
    /** Disease ID (SNOMED code) */
    disease: string

    /** Immunity status object returned by the rules engine.
     *
     * Note this definition covers all possible values;
     * individual diseases should define the subset of fields and values relevant to them.
    */
    status: VaccinationStatus
}

export const DiseaseInfo = ({ disease, status }: DiseaseContentProps) => {
    let content = null

    const disclaimer = (
        <InfoPanel severity="info" title="Disclaimer">
            <Typography variant='bodyTextLarge'>
                Imunis' content is intended for general populations and with reference to vaccination recommendations specific to Hong Kong. Guidance is based on records held on your Imunis account, as added by you or your health provider(s). It does not constitute health advice. Always consult a health professional when considering vaccination. Test results, infection history and other relevant data are not currently captured by Imunis and may affect vaccination recommendations.
            </Typography>
        </InfoPanel>
    )

    if (status) {
        switch (disease) {
            case "840539006":
                content = <CovidContent status={status} />
                break
            case "240532009":
                content = <HpvContent status={status} />
                break
            case "4740000":
                content = <ShinglesContent status={status} />
                break
            case "6142004-NORTH":
                content = <InfluenzaContent status={status} hemisphere="NORTH" />
                break
            case "6142004-SOUTH":
                content = <InfluenzaContent status={status} hemisphere="SOUTH" />
                break
            case "14189004":
                content = <MeaslesContent status={status} />
                break
            case "56717001":
                content = <TuberculosisContent status={status} />
                break
        }

        if (content) {
            content = <>
                <StaticHTML>{content}</StaticHTML>
                {disclaimer}
            </>
        }
    } else {
        // Display JSON content for the disease if available

        if (idContentsMap[disease]) {
            content = (
                <StaticHTML>
                    <JsonContent>
                        {idContentsMap[disease]}
                    </JsonContent>
                </StaticHTML>
            )
        }
    }

    return content
}
