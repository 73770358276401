import { useState, useEffect, useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from "react-router-dom";
import Typography from "../components/typography";
import Grid from '@material-ui/core/Grid';
import Action from '../components/action';
import ErrorDialog from '../components/dialogs/error_dialog';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import ActionDialog from "../components/dialogs/action_dialog";
import QuickAction from '../components/quick_action';
import { BaseContainer } from "../components/container";
import { axiosAuthedInstance } from "../utils/axiosApi";
import EmptyTaskIcon from "../assets/icons/empty_task";
import SharingIcon from '../assets/icons/sharing';
import RecordsIcon from '../assets/icons/records';
import { getActions, transformActionState } from './dashboard.data'
import axios from "axios";
import { isMessageSquelched } from "./invited_dependants";
import { GlobalContext } from "../components/context/globalState";
import ScanDocumentIcon from "../assets/icons/scan_document";
import theme from '../styles/theme'
import Card from '../components/card'
import { Divider } from '../components/divider'
import ListItem from '../components/lists/list_item'
import { TwoLineText } from '../components/two_line_text'
import { EmptyContent } from '../components/empty_content'
import { Skeleton } from '@material-ui/lab'
import { Grow } from '@material-ui/core'

const useEligibilityCardStyles = makeStyles((theme) => ({
    eligibilityCard: {
        padding: 0,
    },
    eligibilityCardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1.5, 2),
        '& > a': {
            whiteSpace: 'nowrap',
        },
    },
    cardDivider: {
        margin: 0,
    },
    eligibilityDisease: {
        margin: theme.spacing(1.5, 2),
    },
    eligibilitySkeleton: {
        marginTop: theme.spacing(2),
    },
    emptyContent: {
        marginBottom: 0,
    }
}))

const EligibilityCards = ({eligibility}) => {
    const classes = useEligibilityCardStyles()
    const [animate, setAnimate] = useState(false)

    useEffect(() => {
        if (eligibility?.length) setAnimate(true)
    }, [eligibility])

    if (eligibility === null) {
        return <Skeleton variant="rect" height={48} className={classes.eligibilitySkeleton}/>
    }

    if (eligibility.length === 0) {
        return (
            <EmptyContent icon={RecordsIcon} className={classes.emptyContent}>
                None of your profiles are eligible for vaccination
            </EmptyContent>
        )
    }

    return eligibility.map(({uuid, full_name, diseases}, i) => (
        <Grow key={uuid} in={animate} timeout={i*theme.transitions.duration.shortest}>
            <div>
                <Card className={classes.eligibilityCard}>
                    <div className={classes.eligibilityCardHeader}>
                        <TwoLineText>
                            <Typography variant="bodyTextMediumBold">
                                {full_name}
                            </Typography>
                        </TwoLineText>

                        <Typography
                            variant="bodyTextMediumPrimary"
                            component={Link}
                            to={`records/${uuid}/health`}>
                            View records
                        </Typography>
                    </div>

                    <Divider className={classes.cardDivider} />

                    <div>
                        {diseases.map(({name}, i) => (
                            <ListItem
                                key={i}
                                divider={i+1 < diseases.length}
                                className={classes.eligibilityDisease}>
                                <Typography variant="bodyTextMediumDark">
                                    {name}
                                </Typography>
                            </ListItem>
                        ))}
                    </div>
                </Card>
            </div>
        </Grow>
    ))
}

const useStyles = makeStyles((theme) => ({
    selector: {
        width: '100%',
        fontSize: '15px',
        color: '#263238',
    },
    select: {
        fontSize: '15px !important',
        height: '20px',
        '&:focus': {
            backgroundColor: theme.palette.common.white
        }
    },
    quickActionWrapper: {
        flexGrow: 1,
        flexBasis: 0,
        textAlign: 'center',
    }
}))

const Home = (props) => {
    const [error, setError] = useState('')
    const [errorOpen, setErrorOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [quickActions, setQuickActions] = useState(['placeholder'])
    const [actions, setActions] = useState([])
    const [eligibility, setEligibility] = useState(null)
    /**
     * Array of user profiles;
     * - The account holder (logged in user). Must be the first entry
     * - Followed by dependants. Doesn't look like this is sorted?
     */
    const [profiles, setProfiles] = useState([])
    const [openSelect, setOpenSelect] = useState(false)
    const [currUserIdx, setCurrUserIdx] = useState(-1)
    const [showAddDialog, setShowAddDialog] = useState(false)
    const history = useHistory()
    const classes = useStyles()

    const { state: globalState } = useContext(GlobalContext)

    useEffect(() => {
        // Wait for request to complete
        if (!globalState.user) return

        setProfiles(us => (
            [{
                uuid: globalState.user?.uuid,
                preferredName: globalState.user?.preferred_name,
                isAccountHolder: true,
            },
            ...us]
        ))
    }, [globalState.user])

    const handleAction = (action, state) => {
        switch (action.type) {
            case 'link':
                history.push(action.url, state)
                break;
            default:
                return;
        }
    }

    useEffect(() => {
        const source = axios.CancelToken.source()
        async function fetchData() {
            try {
                const [actionsResponse, dependantsResponse] = await Promise.all([
                    getActions({
                        cancelToken: source.token
                    }),
                    axiosAuthedInstance.get('/user/dependants/', {
                        cancelToken: source.token
                    })
                ])

                setProfiles(users => users.concat(dependantsResponse.data.data.map(dep => ({
                    uuid: dep.uuid,
                    preferredName: dep.preferred_name,
                }))))

                if (actionsResponse.data.actions) {
                    setActions(actionsResponse.data.actions.map(action => ({
                        ...action,
                        state: transformActionState(action)
                    })))
                    setQuickActions(actionsResponse.data.quick_actions)
                    setEligibility(actionsResponse.data.eligibility)
                }

                if (actionsResponse.data.pending_dependants.length > 0 && !isMessageSquelched()) {
                    history.replace('/pending-connections', {
                        pendingConnections: actionsResponse.data.pending_dependants
                    })
                }

                setLoading(false)
            } catch (error) {
                if (axios.isCancel(error)) return

                console.error(error)
                setLoading(false)
            }
        }

        fetchData()

        return () => {
            source.cancel('Component is unmounting')
        }
    }, [history])

    const userActions = actions.filter(action => (
        currUserIdx == -1 || (action.state?.user?.uuid == profiles[currUserIdx].uuid)
    ));

    return (
        <BaseContainer showNav home
            showOnboardingSteps={
                // We don't want to display the widget if we're going to redirect to
                // /pending-dependants, (as it unsets the first_login flag) so wait until loading is
                // completed.
                loading === false && localStorage.getItem('first_visit') === "true"
            } {...props}>
            <Grid container direction="column" spacing={3}>
                <Grid item container wrap="nowrap">
                    <Grid item className={classes.quickActionWrapper}>
                        <QuickAction
                            icon={SharingIcon}
                            onClick={() => setShowAddDialog(true)}>
                            Add dependant
                        </QuickAction>
                    </Grid>
                    <Grid item className={classes.quickActionWrapper}>
                        <QuickAction
                            icon={RecordsIcon}
                            onClick={() => history.push('/add-vaccine-record')}>
                            Add Covid-19 or Influenza record
                        </QuickAction>
                    </Grid>
                    {quickActions.map((actionId) => {
                        switch (actionId) {
                            case 'paper_records':
                                return (
                                    <Grid key={actionId} item className={classes.quickActionWrapper}>
                                        <QuickAction
                                            icon={ScanDocumentIcon}
                                            onClick={() => history.push('/paper-records/new')}
                                            newBadge>
                                            Digitise paper record
                                        </QuickAction>
                                    </Grid>
                                )
                            case 'placeholder':
                                return (
                                    <Grid key={actionId} item className={classes.quickActionWrapper}>
                                        <QuickAction placeholder />
                                    </Grid>
                                )
                        }
                    })}
                </Grid>

                <Grid item container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="bodyTextLargeBold">
                            Current vaccine eligibility:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="bodyTextMedium">
                            For Imunis tracked vaccinations - does not include ‘unavailable’ status. Review records in full with your health provider.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <EligibilityCards eligibility={eligibility} />
                    </Grid>
                </Grid>

                <Grid item container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="bodyTextLargeBold">
                            Tasks
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography variant="bodyTextMedium">
                            Task list shows actions to best manage your Imunis account
                        </Typography>
                    </Grid>

                    {loading ? (
                        <Grid item>
                            <Skeleton variant="rect" height={40}/>
                        </Grid>
                    ) : profiles.length > 1 && (
                        <Grid item>
                            <ClickAwayListener onClickAway={() => setOpenSelect(false)}>
                                <FormControl className={classes.selector} variant="outlined" size="small">
                                    <InputLabel>View task for</InputLabel>
                                    <Select
                                        classes={{ select: classes.select }}
                                        open={openSelect}
                                        onOpen={() => setOpenSelect(true)}
                                        onClose={() => setOpenSelect(false)}
                                        native
                                        value={currUserIdx}
                                        onChange={(event) => {
                                            setCurrUserIdx(event.target.value)
                                        }}
                                        label="View task for"
                                    >
                                        <option key='family' value={-1}>
                                            All family
                                        </option>
                                        {profiles.map((profile, k) => (
                                            <option key={k} value={k}>
                                                {profile.preferredName}
                                                {profile.isAccountHolder && ' (Me)'}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </ClickAwayListener>
                        </Grid>
                    )}

                    {userActions.length > 0 ? (
                        <Grid item>
                            {userActions.map(action => (
                                <Action
                                    key={action.key}
                                    type={action.type}
                                    title={action.title}
                                    content={action.content}
                                    on_click={() => handleAction(action.action, action.state)} />
                            ))}
                        </Grid>
                    ) : !loading && (
                        <Grid item container direction="column" alignItems="center" style={{
                            marginTop: theme.spacing(5),
                        }}>
                            <Grid item style={{
                                color: theme.palette.grey[600],
                            }}>
                                <EmptyTaskIcon />
                            </Grid>
                            <Grid item>
                                <Typography variant="bodyTextSmall">
                                    Your tasks are complete
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <ErrorDialog error={error} setError={setError} open={errorOpen} setOpen={setErrorOpen} />
            <ActionDialog
                cancel
                title='Add a new dependant'
                content={'You can manage the records & profile of the dependants that you enter manually. ' +
                    'Or “scan QR code” to add view-only dependant from another account holder.'}
                action_label='Enter information manually'
                action_callback={() => history.push('/dependants/add')}
                cancel_label='Scan QR code'
                cancel_callback={() => history.push('/dependants/scan')}
                openDialog={showAddDialog}
                setOpenDialog={setShowAddDialog}
            />
        </BaseContainer>
    )
}
export default Home;
